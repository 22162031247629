import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueI18n from "vue-i18n";
import VueParticles from "vue-particles";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import "@/common/font.css";
import { Popup } from "vant";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.use(VueParticles);
Vue.use(AOS);
Vue.use(Popup);

const i18n = new VueI18n({
  locale: "zh-CN", // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  messages: {
    "zh-CN": require("./common/zh"), // 中文语言包
    "en-US": require("./common/en"), // 英文语言包
  },
});

router.beforeEach((to, from, next) => {
  window.pageYOffset =
    document.documentElement.scrollTop =
    document.body.scrollTop =
      0;
  next();
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
