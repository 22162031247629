import Vue from "vue";
import VueRouter from "vue-router";
import { checkMobile } from "@/utils/util";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () => {
      if (checkMobile()) return import("@/views/mobile/layout.vue");
      return import("@/views/layout.vue");
    },

    children: [
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/aboutUs.vue");
          return import("@/views/aboutUs.vue");
        }, //关于我们
      },
      {
        path: "/brandCenter",
        name: "brandCenter",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/brandCenter.vue");
          return import("@/views/brandCenter.vue");
        }, //品牌中心
      },
      {
        path: "/commerceOperation",
        name: "commerceOperation",
        component: () => {
          if (checkMobile())
            return import("@/views/mobile/commerceOperation.vue");
          return import("@/views/commerceOperation.vue");
        }, // 电商运营
      },
      {
        path: "/customerService",
        name: "customerService",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/customerService");
          return import("@/views/customerService");
        }, //客户服务
      },
      {
        path: "/expansion",
        name: "expansion",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/expansion");
          return import("@/views/expansion");
        }, //拓新
      },
      {
        path: "/supplyService",
        name: "supplyService",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/supplyService");
          return import("@/views/supplyService");
        }, //供应链服务
      },
      {
        path: "/joinUs",
        name: "joinUs",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/joinUs");
          return import("@/views/joinUs");
        }, //加入我们
      },
      {
        path: "/technologyCenter",
        name: "technologyCenter",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/technologyCenter");
          return import("@/views/technologyCenter");
        }, //技术中心
      },
      // {
      //   path: "/joinUs",
      //   name: "joinUs",
      //   component: () => {
      //     if (checkMobile()) return import("@/views/mobile/joinUs");
      //     return import("@/views/joinUs");
      //   }, //加入我们
      // },
      {
        path: "/trade",
        name: "trade",
        component: () => {
          if (checkMobile()) return import("@/views/mobile/trade");
          return import("@/views/trade");
        }, //跨境贸易
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    component: () => {
      if (checkMobile()) return import("@/views/mobile/home");
      return import("@/views/home");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
