export const lang = {
  tabs: [
    { title: "首页", path: "/home", active: 0 },
    { title: "关于我们", path: "/aboutUs", active: 1 },
    { title: "业务结构", path: "/commerceOperation", active: 2 },
    { title: "拓新", path: "/expansion", active: 3 },
    { title: "品牌中心", path: "/brandCenter", active: 4 },
    { title: "加入我们", path: "/joinUs", active: 5 },
  ],
  headerBusiness: {
    titleOne: "电商运营",
    titleTwo: "跨境贸易",
    titleThree: "IT解决方案",
    titleFour: "供应链服务",
    titleFive: "客户服务",
  },
  footer: {
    titleOne: "认识一柠",
    titleTwo: "业务范围",
    titleThree: "品牌中心",
    titleFour: "加入我们",
  },
  home: {
    titleOne: "走向世界",
    titleTwo: "让生活更美好 让工作更高效",
    detail: {
      title: "关于我们",
      subTitle:
        "一柠以精细化运营策略深耕垂直领域市场，致力于构建一个可持续发展的电子商务供应链体系",
      container:
        "业务涉及电商运营、IT解决方案、供应链服务、客户服务、跨境贸易五大版块。一柠始终坚持以客户为中心，加大产品研发和科技研发的投入，努力为企业提供更智能的管理、更高效的工作助力；坚持为消费者提供优质的产品和服务；更好地承担社会责任并为社会创造更多的价值。",
      more: "了解更多",
      desc: "",
    },
    advantage: {
      title: "优势及服务",
      itemOne: {
        title: "一站式服务",
        desc: "一柠提供针对品牌电商的一站式解决方案和服务，包括网店和购物网站的建设、店站运营、数字营销、订单管理、仓储物流、订单履约及客户服务等。",
      },
      itemTwo: {
        title: "国际视野 全球落地",
        desc: "一柠致力于成为国际国内品牌数字战略的诠释者和实践者，并将领先市场的领先解决方案推向全球。",
      },
      itemThree: {
        title: "全链路",
        desc: "物理空间和数字空间的不断结合，给消费者带来更加丰富多彩的购物历程。",
      },
      itemFour: {
        title: "全渠道",
        desc: "在不同的渠道，品牌都希望消除边界，提供无缝的消费者购物场景，即无处不在、无时不在的商品提供、服务体验和客户权益。",
      },
      itemFive: {
        title: "技术与创新",
        desc: "今天的品牌电商发展推动了整体的零售模式变革，这一变革又引发了技术的变革，产生了巨大的创新空间。",
      },
      service: "我们的服务",
    },
  },
  aboutUs: {
    symbol: "：",
    title: "我们的企业文化",
    titles: "业务概述",
    itemOne: {
      title: "人才观",
      desc: "吸引人才、尊重人才；人尽其才，才尽其用",
    },
    itemTwo: {
      title: "价值观",
      desc: "诚信、敬业、勇敢、拓新",
    },
    itemThree: {
      title: "使命",
      desc: "走向世界，让生活更美好，让工作更高效",
    },
    itemFour: {
      title: "愿景",
      desc: "持续发展；持续拓新",
    },
  },
  commerce: {
    bannerTitle: "电商运营",
    detailOne:
      "一柠对自己的品牌和经销品牌负责，以精细化运营策略深耕垂直领域市场",
    detailTwo:
      "目前，一柠旗下公司在淘系、京东、拼多多、亚马逊、速卖通等电商平台全渠道运营，拥有多家优质店铺",
    importDetail: "类目涵盖五大类：服饰、环境电器、医疗器械、宠物用品、家纺",
    coreTitle: "核心竞争力",
    contentOne: "十年以上电商运营经验",
    contentTwo: "汇聚电商精英人才",
    contentThree: "完善的供应链体系",
  },
  trade: {
    bannerTitle: "跨境贸易",
    titlesOne: "跨境电商服务",
    parpasOne:
      "全方位流程化服务 · 精准化人群定位 · 高潜力爆款打造 · 带你掘金全球市场",
    titlesTwo: "服务优势",
    itemOne: {
      title: "资深运营推广团队",
      desc: "一柠服务团队具有丰富的实战经验以及深厚的理论基础，带您少走弯路，快速成为跨境赢家。",
    },
    itemTwo: {
      title: "丰富的平台渠道资源",
      desc: "拓展全球更多销售渠道，直接面向海外终端客户，使企业品牌影响力在客户心中生根发芽。",
    },
    itemThree: {
      title: "站内外流量精准布局",
      desc: "海量数据分析，精准渠道广告投放，稳健提升商品流量以及转化率。",
    },
  },
  supply: {
    bannerTitle: "供应链服务",
    titles: "服务内容",
    supplyDetail:
      "一柠始终致力于帮助品牌通过改善供应链，以实现销售和利润的实际化提升，在提高库存管理水平的同时，优化成本控制，广泛应用于品牌供应链体系并得到了品牌的高度认可",
    yini: "一柠「全链式」仓储物流服务",
    itemOne: {
      partname: "快",
      partContent: "订单处理快速",
    },
    itemTwo: {
      partname: "准",
      partContent: "准点发货不延迟",
    },
    itemThree: {
      partname: "精",
      partContent: "精准把控库存",
    },
  },
  customerService: {
    bannerTitle: "客服服务",
    custmdiv:
      "客户服务不仅是一柠重要构成部分，也是一柠的文化之一。大处着眼，小处入手；一柠注重细节，提倡客户的专属化服务理念，倾听用户体验，解决诉求，全身心融入与用户互动的过程",
    titleOne: "电商服务",
    parpsOne: "确保专业性的同时，做到信息处理的及时性和一站式服务支持",
    titleTwo: "企业服务",
    parpsTwo: "标准化专业培训，专人专项，全程跟踪服务",
  },
  expansion: {
    bannerTitle: "开拓创新",
    titlesOne: "创新优势",
    itemOne: {
      title: "产学研究深度合作",
      desc: "力邀一流大学科学家加盟，带领研究团队参与企业级创新方案规划。",
    },
    itemTwo: {
      title: "先进的实施理念",
      desc: "以产品思维取代项目思维，将产品化的理念贯彻到创新的每一个环节，每一个人。",
    },
    itemThree: {
      title: "牢靠的根基",
      desc: "先进的云计算技术平台，支撑起以一柠多年品牌电商运营经验培育的电商云生态平台。",
    },
    itemFour: {
      title: "标准化能力",
      desc: "通过标准化的数据，打造标准化的产品，使一柠具备提供标准化科技服务的能力。",
    },
    titleTop: "创新服务",
    cloudItemO: {
      title: "云计算平台",
      descOne: "·提供标准化的服务接口与数据接口",
      descTwo: "·电商基础服务产品化",
    },
    cloudItemT: {
      title: "云生态平台",
      descOne: "·面向不同体量级别的品牌客户",
      descTwo: "·依照需求制定规划，精简流程",
      descThree: "·优化预算支出与业务能力",
    },
  },
  brandCenter: {
    bannerTitle: "品牌中心",
    titles: "合作伙伴",
  },
  technologyCenter: {
    bannerTitle: "IT技术支持",
    titles: "IT解决方案",
  },
  joinUs: {
    joinTitle: "加入我们",
    addressTitle: "公司地址",
    address: "上海市闵行区虹桥汇T6 512",
    bemailTitle: "商务邮箱",
    remailTitle: "简历邮箱",
    descibe: "职位描述",
    requirement: "任职要求",
    extension: {
      title: "推广类岗位",
      itemOne: {
        title: "海外广告投放/推广",
        desc: {
          descOne:
            "1、负责产品海外推广平台竞价及数据分析，根据分析结果调整竞价推广，对投放渠道 \xa0\xa0\xa0\xa0\xa0 ROI负责；",
          descTwo:
            "2、监控广告的实际投放结果，进行实时优化，并提出和执行相应解决对策；",
          descThree: "3、挖掘产品卖点，确定针对产品得推广政策；",
          descFour: "4、独立分析广告投放数据，总结投放问题，拟定解决策略；",
          descFive:
            "5、收集/总结/分析产品营销过程中的市场信息，提出建设性意见。",
        },
        require: {
          descOne: "1、1年以上海外广告投放者优先；",
          descTwo: "2、熟悉海外投放规则及广告的各种形式；",
          descThree: "3、有较强的数据分析能力，思维敏捷，对数据敏感。",
        },
      },
      itemTwo: {
        title: "海外社交媒体运营",
        desc: {
          descOne:
            "1、负责Facebook/Facebook Group/Instagram/Pinterest/YouTube等品牌账号日常运 \xa0\xa0\xa0\xa0\xa0 营（内容产出、粉丝维护、舆情监控等）；",
          descTwo: "2、根据时事、热点、节庆等策划社媒活动；",
          descThree:
            "3、拓展社媒红人，运用多种合作方式，提升品牌账号粉丝量、活跃度；",
          descFour: "4、定期进行数据分析，效果总结，运营策略优化；",
          descFive: "5、关注行业前沿信息和竞品营销事件。",
        },
        require: {
          descOne: "1、有一年以上海外社交媒体运营经验，对推广工作充满热情；",
          descTwo: "2、较强的文案、策划、沟通协调能力；",
          descThree: "3、英语可作为工作语言，较强的英语书写能力；",
          descFour: "4、熟练使用软件进行图片、视频创作；",
          descFive: "5、积极主动、执行力强、有团队合作精神；",
          descSix: "6、有家居/宠物类红人资源的优先；",
          descSeven: "7、若有营销案例，请面试时展示。",
        },
      },
      itemThree: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemFour: {
        title: "独立站推广",
        desc: {
          // descOne: "独立站推广",
          descOne:
            "1、利用Facebook、Instagram、Youtube、论坛、博客等流量渠道进行推广，执行推 \xa0\xa0\xa0\xa0\xa0 广策略方案，持续提升独立站流量及转化；",
          descTwo:
            "2、负责独立调研市场的数据，输出报告并进行广告测试，预测销量走势；",
          descThree:
            "3、结合GoogleAnalystic等相关工具，优化独立站用户体验，提升转化率；",
          descFour: "4、对于推广问题和异常波动分析原因并提出解决方案。",
        },
        require: {
          descOne:
            "1、985/211院校优先，英语CET-6及以上水平，具备良好的读写能力；",
          descTwo: "2、良好的数据分析能力；",
          descThree:
            "3、做事认真、负责、仔细，有较好的沟通能力和快速学习能力；",
          descFour: "4、办公软件操作熟练。",
        },
      },
    },
    operate: {
      title: "运营类岗位",
      itemOne: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemTwo: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemThree: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemFour: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemFive: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemSix: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
    },
  },
};
