export const lang = {
  tabs: [
    { title: "Home", path: "/home", active: 0 },
    { title: "About Us", path: "/aboutUs", active: 1 },
    { title: "Business Structure", path: "/commerceOperation", active: 2 },
    { title: "Innovation", path: "/expansion", active: 3 },
    { title: "Brand Center", path: "/brandCenter", active: 4 },
    { title: "Join Us", path: "/joinUs", active: 5 },
  ],
  headerBusiness: {
    titleOne: "E-commerce operation",
    titleTwo: "Cross-Border Trade",
    titleThree: "IT Solution",
    titleFour: "Supply Chain Services",
    titleFive: "Customer Services",
  },
  footer: {
    titleOne: "About One Lime",
    titleTwo: "What We Do",
    titleThree: "Brand Center",
    titleFour: "Join Us",
  },
  home: {
    titleOne: "Let Lime Enter the World",
    titleTwo: "To make life better, To make work easier",
    detail: {
      title: "About Us",
      subTitle:
        "With dedication to the vertical market, we are engaged in building a sustainable e-commerce supply chain system through delicacy management",
      container:
        "Our business covers five major sectors, namely e-commerce operations, IT solutions, supply chain services, customer services and cross-border trade." +
        "Providing smarter management and more efficient work assistance is the single biggest driver of our customer-centric endeavor and dictates our continuous investment in product and technology R&D." +
        "We remain committed to offering consumers high-quality products and services in ways that better live up to social responsibilities and contribute to our society.",
      more: "Learn More",
      desc: "",
    },
    advantage: {
      title: "Our Advantages and Services",
      itemOne: {
        title: "One-Stop Services",
        desc: "Our solutions and services are visible at every step of your Yining journey, including the building of online stores and shopping websites, store-based operations, digital marketing, order management, warehousing and logistics, order fulfillment and customer services.",
      },
      itemTwo: {
        title: "Our International Perspective and Global Forays",
        desc: "Looking beyond the borders, we give a concrete expression to the digital strategy of brands and promote pioneering solutions to the world.",
      },
      itemThree: {
        title: "The All-Link Model",
        desc: "The continuous merging of physical and digital space rewards our customers with a more all-encompassing shopping experience.",
      },
      itemFour: {
        title: "The Multichannel Model",
        desc: "We aim at creating a seamless shopping environment without boundaries. Customers can enjoy sound product supplies, pleasant service experience and reliable legal protection whenever and wherever they go.",
      },
      itemFive: {
        title: "Technology and Innovation",
        desc: "We believe in the transformative power of brand e-commerce propelled by innovation and want to change the retail model for the better. ",
      },
      service: "Our services",
    },
  },
  aboutUs: {
    symbol: ":",
    title: "Our Corporate Ethics",
    titles: "Business Scope",
    itemOne: {
      title: "Our views on talents",
      desc: " \xa0Attract and respect the great minds; Bring the best out of our employees and give full play to their talents",
    },
    itemTwo: {
      title: "Our values",
      desc: " \xa0Integrity, dedication, courage, innovation",
    },
    itemThree: {
      title: "Our missions",
      desc: " \xa0Serve globally, live happily and work efficiently",
    },
    itemFour: {
      title: "Our visions",
      desc: " \xa0Sustained development and enduring innovation",
    },
  },
  commerce: {
    bannerTitle: "E-commerce Operation",
    detailOne:
      "Self-driven to deliver the best experience for our own brands and our dealer’s brands, we cultivate our vertical market through delicacy management",
    detailTwo:
      "At present, numerous high-quality online stores are run by our companies on many e-commerce platforms, such as JD.COM, Pinduoduo, Amazon, AliExpress and those operated by Taobao",
    importDetail:
      "Our business covers five areas, spanning the gamut from clothing to environmental appliances, medical equipment, pet products and home textiles",
    coreTitle: "Core Competence",
    contentOne: "10 years plus of e-commerce operation experience",
    contentTwo: "A star-studded e-commerce circle",
    contentThree: "A sound supply chain system",
  },
  trade: {
    bannerTitle: "Cross-Border Trade",
    titlesOne: "Cross-Border E-commerce Services",
    parpasOne:
      "All-round process service · Targeted group positioning · Launching hit products with enormous potential · Making inroads into the global market",
    titlesTwo: "Service Advantages",
    itemOne: {
      title: "Senior Operation and Promotion Team",
      desc: "With a solid theoretical foundation and much battle-shaped experience, our team will put you on the yellow brick road in the cross-border business.",
    },
    itemTwo: {
      title: "Rich Platform and Channel Resources",
      desc: "Embracing our end clients globally, we strive to earn their applaud and keep their trust.",
    },
    itemThree: {
      title: "Accurate Layout of Internal and External Traffic",
      desc: "Our mass data analysis and our targeted advertising are contributing to the growth in product traffic and conversion rate.",
    },
  },
  supply: {
    bannerTitle: "Supply Chain Services",
    titles: "Service Contents",
    supplyDetail:
      "We are committed to assisting brands in improving their supply chain system to boost sales and profits. Our inventory management and cost control mechanism has won recognition far and wide",
    yini: "Our “all-link” model of warehousing and logistics services",
    itemOne: {
      partname: "Quick",
      partContent: "Orders are processed in rapid response to demands",
    },
    itemTwo: {
      partname: "On-time",
      partContent: "Deliveries are made without delay",
    },
    itemThree: {
      partname: "Accurate",
      partContent: "Inventory is handled in a targeted manner",
    },
  },
  customerService: {
    bannerTitle: "Customer Services",
    custmdiv:
      "We deliver above and beyond. Down to earth and true to self, we tailor services for our customers and are always here to listen, to serve, and to interact",
    titleOne: "E-commerce Services",
    parpsOne:
      "With our one-stop service, information can be processed in time and services can be provided professionally",
    titleTwo: "Enterprise Services",
    parpsTwo:
      "Our professionally trained team are poised to provide follow-up service all the way by your side",
  },
  expansion: {
    bannerTitle: "Break New Grounds",
    titlesOne: "Advantages Regarding Innovation",
    itemOne: {
      title: "In-Depth Cooperation among Industries, Universities and Research",
      desc: "We have created a gravitational pull of scientists from world-class universities. Their team are lending their support to our enterprise-level innovation planning.",
    },
    itemTwo: {
      title: "Pioneering Thoughts on Implementation",
      desc: "Project-based thinking have taken the place of product-based thinking. The concept of productization has percolated through every link of our innovation in an effort where everyone pitches in.",
    },
    itemThree: {
      title: "A Solid Foundation",
      desc: "Over the years, we have built a cutting-edge cloud computing technology platform that supports our e-commerce cloud ecological platform.",
    },
    itemFour: {
      title: "Abilities Featuring Standardization ",
      desc: "Our standardized data and standardized products enable us to provide standardized services in science and technology.",
    },
    titleTop: "Innovation services and solutions ",
    cloudItemO: {
      title: "Cloud Computing Platform",
      descOne: "·Provide standardized service interface and data interface",
      descTwo: "·The productization of basic e-commerce services",
    },
    cloudItemT: {
      title: "Cloud Ecological Platform",
      descOne: "·Provide services for brand clients of different sizes",
      descTwo:
        "·Devise plans in accordance with requirements and streamline processes",
      descThree:
        "·Optimize budget expenditures and improve capabilities to do business",
    },
  },
  brandCenter: {
    bannerTitle: "Brand Center",
    titles: "Cooperative Partner",
  },
  technologyCenter: {
    bannerTitle: "IT Support",
    titles: "IT Solution",
  },
  joinUs: {
    joinTitle: "Join Us",
    addressTitle: "Company Address",
    address:
      " Room 512, Tower 6, Building Hongqiaohui, Minhang District, Shanghai\n",
    bemailTitle: "Contact Us",
    remailTitle: "Join Us",
    descibe: "Job Summary",
    requirement: "Qualifications",
    extension: {
      title: "Promotional Positions",
      itemOne: {
        title: "Overseas Advertising/Promotion",
        desc: {
          descOne:
            "1. Handles bidding and data analysis of overseas promotion platforms, adjusts plans \xa0\xa0\xa0\xa0 accordingly, and be responsible for the advertising channel ROI. ",
          descTwo:
            "2. Monitors the dynamics of advertising and carries out real-time optimizations, \xa0\xa0\xa0\xa0\xa0Proposes and implements appropriate solutions. ",
          descThree:
            "3. Explores the selling points of our products and puts forward relevant promotion \xa0\xa0\xa0\xa0\xa0strategies. ",
          descFour:
            "4. Independently analyzes advertising data, reflects on the problems and comes up \xa0\xa0\xa0\xa0wit solutions.",
          descFive:
            "5. Collects/summarizes/analyzes the market information while marketing and  \xa0\xa0\xa0\xa0proposes constructive suggestions.",
        },
        require: {
          descOne:
            "1. Applicants with more than one year of overseas advertising experience are  \xa0\xa0\xa0\xa0preferred. ",
          descTwo:
            "2. Familiar with overseas advertising rules and various forms of advertisement. ",
          descThree:
            "3. Excellent data analysis ability, quick thinking, and sensitivity to data.",
        },
      },
      itemTwo: {
        title: "Overseas Social Media Operations",
        desc: {
          descOne:
            "1. Responsible for the daily operation of Facebook/Facebook  \xa0\xa0\xa0\xa0Group/Instagram/Pinterest/YouTube and other brand accounts (content production,  \xa0\xa0\xa0\xa0fanbase maintenance, public opinion monitoring, etc.)   ",
          descTwo:
            "2. Organizes social media activities based on current affairs, hot topics, festivals, etc.   ",
          descThree:
            "3. Connects and cooperates with social media influencers to expand the fanbase and \xa0\xa0\xa0\xa0attract more active users.",
          descFour:
            "4. Conducts data analysis on a regular basis, summarizes the outcomes, and \xa0\xa0\xa0\xa0improves operation strategies.   ",
          descFive:
            "5. Keeps a close eye on the latest information and the marketing cases that involve  \xa0\xa0\xa0\xa0competing goods.",
        },
        require: {
          descOne:
            "1. More than one year of experience as an operator of overseas social media and \xa0\xa0\xa0\xa0passion for promotional work.",
          descTwo:
            "2. Excellent copywriting, scheming, communication and coordination skills.",
          descThree:
            "3. Good at English writing, with English as a working language.",
          descFour:
            "4. Proficient in using softwares to create pictures and videos.",
          descFive: "5. Proactive, effective at execution, and team spirit.",
          descSix: "6. Household/pet influencer resources will be a plus.",
          descSeven:
            "7. Your own marketing cases are welcomed during the interview.",
        },
      },
      itemThree: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemFour: {
        title: "Independent Website Promotion",
        desc: {
          descOne:
            "1. Responsible for promotion via traffic channels such as Facebook, Instagram, \xa0\xa0\xa0\xa0YouTube, forums, blogs. Implements promotion strategies and sustains the traffic \xa0\xa0\xa0\xa0on independent websites and its conversion rate. ",
          descTwo:
            "2. Independently analyzes market data, gives reports, conducts advertising tests, and \xa0\xa0\xa0\xa0predicts sales trends.",
          descThree:
            "3. Optimizes the user experience on independent websites and increases the \xa0\xa0\xa0\xa0conversion rate with the help of tools like GoogleAnalystic.",
          descFour:
            "4. Analyzes the causes of abnormal fluctuations and other problems in promotion and \xa0\xa0\xa0\xa0offers solutions.",
        },
        require: {
          descOne:
            "1. Applicants graduating from 985/211 universities /QS100 universities are preferred. \xa0\xa0\xa0\xa0CET-6 or above. A good command of English reading and writing.",
          descTwo: "2. Good data analysis ability.",
          descThree:
            "3. Conscientious, responsible and careful, able to communicate well and learn fast.",
          descFour: "4. Highly proficient in using MS office tool kit.",
        },
      },
    },
    operate: {
      title: "运营类岗位",
      itemOne: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemTwo: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemThree: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemFour: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemFive: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
      itemSix: {
        title: "",
        desc: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
        },
        require: {
          descOne: "",
          descTwo: "",
          descThree: "",
          descFour: "",
          descFive: "",
          descSix: "",
          descSeven: "",
        },
      },
    },
  },
};
